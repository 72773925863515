import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import 'firebase/database';
import axios from 'axios';

//base URL for SQL query
let baseURL = "";

if (process.env.REACT_APP_PROJECT_ID === "sail-dev-62f69") {
	//DEV
	baseURL = "https://us-central1-sail-dev-62f69.cloudfunctions.net/app";
} else if (process.env.REACT_APP_PROJECT_ID === "sail-staging-4dcfb") {
	//STAGE
	baseURL = "https://us-central1-sail-staging-4dcfb.cloudfunctions.net/app";
} else if (process.env.REACT_APP_PROJECT_ID === "eco-seeker-310618") {
	//PRODUCTION
	baseURL = "https://us-central1-eco-seeker-310618.cloudfunctions.net/app";
}

// testing emulators URL
if (window.location.hostname === "localhost") {
	baseURL = "http://localhost:5001/eco-seeker-310618/us-central1/app";
}

const firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
auth.setPersistence(firebase.auth.Auth.Persistence.SESSION); //each tabs has different uid; uid store in session
// auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL); //same uid in different tabs; uid store in indexDB

const firestore = firebase.firestore();
const functions = firebase.functions();
const db = firebase.database();

//Use firebase emualator when running React locally.
if (window.location.hostname === "localhost") {
	// functions.useEmulator("http://localhost:5001");
	functions.useEmulator("localhost", 5001);
	firestore.useEmulator("localhost", 8080);
	auth.useEmulator("http://localhost:9099/", { disableWarnings: true });
}
export { firestore, auth, functions, db };

// Helper function to track user visit
const trackUserVisit = async (chatbotId, userId, userData = {}) => {
	try {
		// Collect visitor data
		const visitorData = {
			sessionId: Date.now().toString(),
			pageUrl: window.location.href,
			referrer: document.referrer,
			userAgent: navigator.userAgent,
			// Include user information
			userName: userData.userName || '',
			userEmail: userData.userEmail || '',
			userFirstName: userData.userFirstName || userData.userName || ''
		};

		const response = await axios.post(`${baseURL}/api/analytics/visit`, {
			chatbotId,
			userId,
			visitorData
		});

		console.log('Visit tracking response:', response.data);
		return response.data?.data?.tracked || false;
	} catch (error) {
		console.error('Error tracking visit:', error);
		return false;
	}
};

export const signInWithGoogle = async (chatbotId) => {
	const provider = new firebase.auth.GoogleAuthProvider();
	try {
		console.log('Starting Google sign-in process');
		const result = await auth.signInWithPopup(provider);
		return auth;
	} catch (err) {
		console.error('Error in Google sign-in process:', err);
		throw err;
	}
};

export const signInWithMicrosoft = async (chatbotId) => {
	const provider = new firebase.auth.OAuthProvider('microsoft.com');
	try {
		const result = await auth.signInWithPopup(provider);
		return auth;
	} catch (err) {
		console.error('Error signing in with Microsoft:', err);
		throw err;
	}
};

export const getUserOnLoad = async (chatbotId) => {
	return new Promise(async function (resolve, reject) {
		var user = auth.currentUser;

		if (user) {
			resolve(user);
		} else {
			try {
				const result = await auth.signInAnonymously();
				resolve(result.user);
			} catch (err) {
				reject("error");
			}
		}
	});
};

export const getUserStatus = async () => {
	console.log("getUserStatus");

	// Get the current user ID or generate a unique ID for anonymous users
	const userId = firebase.auth().currentUser?.uid;

	if (userId) {
		// Update the user status to "online"
		updateUserStatus(userId, 'online');

		// Set up a listener for user presence changes
		const authStateChangedListener = firebase.auth().onAuthStateChanged((user) => {
			console.log("getUserStatus userId", user?.uid);
			if (user) {
				// User is signed in
				updateUserStatus(user.uid, 'online');
				console.log("getUserStatus online");
			} else {
				// User is signed out
				updateUserStatus(userId, 'offline');
				console.log("getUserStatus offline");
			}
		});

		// Set up a listener for beforeunload and unload events
		const handleUnload = () => {
			console.log("handleUnload");
			updateUserStatus(userId, 'offline');
		};

		window.addEventListener('beforeunload', handleUnload);
		window.addEventListener('unload', handleUnload);

		// Return the cleanup function
		return () => {
			console.log("cleanup getUserStatus");
			authStateChangedListener(); // Unsubscribe onAuthStateChanged listener
			window.removeEventListener('beforeunload', handleUnload);
			window.removeEventListener('unload', handleUnload);
		};
	} else {
		console.log("User ID not available. Skipping user status update.");
		return () => { }; // Return an empty cleanup function if userId is not available
	}
};

// Function to update user status
const updateUserStatus = (userId, status) => {
	const userRef = firebase.firestore().collection('rooms').doc(userId);
	userRef.update({ status });
};

// Export trackUserVisit so it can be used directly in components
export { trackUserVisit };