import { useEffect, useState, useRef } from "react";
import { ThemeProvider } from "@material-ui/core";
import theme from "./styles/theme";
import ChatbotBody from "./containers/ChatbotBody";
import ChatWindowButton from "./components/ChatWindowButton";
import { useStateValue } from "./context/state/provider";
import { applyClientBranding } from "./helpers/brandingHelpers";
import {
	resizeGlobalFonts,
	updateVisibilitySetting,
} from "./helpers/accessibilityHelpers";
import { FONT_SIZE } from "./constants/fontSize.constants";
import { VISIBILITY } from "./constants/visibility.constants";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { firestore, auth, db, functions, trackUserVisit } from "./services/firebase/firebase";
import "firebase/firestore";
import firebase from "firebase/app";
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import writeGreetingsOptions from "./helpers/writeGreetingsOptions";
import writeForm from "./helpers/writeForm";
import { VIEW } from "./constants/view.constants";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType, InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import axios from "axios";

//base URL for SQL query
let baseURL = "";
//parent URL for post messages for embedding
let parentURL = "";

if (process.env.REACT_APP_PROJECT_ID === "sail-dev-62f69") {
	//DEV
	baseURL = "https://us-central1-sail-dev-62f69.cloudfunctions.net/app";
	// parentURL = "https://stage.groundfloor.co" //deploy dev site
	parentURL = "http://localhost:9006"; //to make the embed chatbot work correctly with admin portal running locally
	// console.log = function(){};
} else if (process.env.REACT_APP_PROJECT_ID === "sail-staging-4dcfb") {
	//STAGE
	baseURL = "https://us-central1-sail-staging-4dcfb.cloudfunctions.net/app";
	parentURL = "https://stage.groundfloor.co"; //to make the embed chatbot work correctly
	// parentURL = "http://localhost:9006"; //to make the embed chatbot work correctly with admin portal running locally
	//This stop console.log
	// console.log = function(){};
	//This is to disable react dev tools
	// disableReactDevTools();
} else if (process.env.REACT_APP_PROJECT_ID === "eco-seeker-310618") {
	//PRODUCTION
	baseURL = "https://us-central1-eco-seeker-310618.cloudfunctions.net/app";
	parentURL = "https://groundfloor.co"; //to make the embed chatbot work correctly
	//This stop console.log
	// console.log = function () { };
	//This is to disable react dev tools
	disableReactDevTools();
}

// testing emulators URL
baseURL = "http://localhost:5001/eco-seeker-310618/us-central1/app";
// baseURL = "http://localhost:5001/sail-dev-62f69/us-central1/app";
// baseURL = "http://localhost:5001/sail-staging-4dcfb/us-central1/app";
// parentURL = "http://localhost:9006"; //to make the embed chatbot work correctly

console.log("baseURL", baseURL);

function extractTokenFromUrl() {
	// Get the URL query parameters
	const queryString = window.location.search;

	// Parse the query string into an object
	const queryParams = new URLSearchParams(queryString);

	// Get the value of the 'token' parameter
	const token = queryParams.get('token');
	console.log(token);
	return token;
}

//This to detect clientId and base on its change switch chatbot
function getQueryVariable(variable) {
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split("=");
		if (pair[0] === variable) { return pair[1]; }
	}
	return (false);
}

async function getUrlValues(clientId) {
	console.log("clientId", clientId);
	const userBotRef = firestore.collection("chatbots").doc(clientId);
	const docSnapshot = await userBotRef.get();
	if (docSnapshot.exists) {
		const data = docSnapshot.data();
		console.log("data", data);
		return data.allowedOrigin;
	} else {
		return [];
	}
}

async function getTimekeeperValues(clientId) {
	console.log("clientId", clientId);
	const userBotRef = firestore.collection("chatbots").doc(clientId);
	const docSnapshot = await userBotRef.get();
	if (docSnapshot.exists) {
		const data = docSnapshot.data();
		console.log("data", data);
		return {
			timeKeeper: data.timeKeeper,
			timeKeeperInitialDelay: data.timeKeeperInitialDelay,
			timeKeeperRecurringDelay: data.timeKeeperRecurringDelay,
			chatbotName: data.name,
			fallbackWaitingTime: data.fallbackWaitingTime,
		}
	} else {
		return null;
	}
}

async function messageToFirestore(messagesArr, fallbackMessage, options, provider, tmpRoomId, chatbotName) {
	//If there is one or more greetings in BQ use them
	if (messagesArr.length > 0) {
		let i = 0;
		for (const msg of messagesArr) {
			await firestore.collection("rooms").doc(tmpRoomId).collection("messages").add(
				//if last message add options else not
				i === messagesArr.length - 1 ?
					{
						text: msg,
						uid: provider,
						// createdAt: new Date(), //firestore.FieldValue.serverTimestamp(),
						createdAt: firebase.firestore.FieldValue.serverTimestamp(),
						options: options,
						greeting: true,
						chatbotName: chatbotName,
						isTimeKeeper: false
					}
					:
					{
						text: msg,
						uid: provider,
						// createdAt: new Date(), //firestore.FieldValue.serverTimestamp(),
						createdAt: firebase.firestore.FieldValue.serverTimestamp(),
						greeting: true,
						chatbotName: chatbotName,
						isTimeKeeper: false
					}
			);
			i++;
		}
	} else {
		//else if not messages in BQ use the hardcoded one
		await firestore.collection("rooms").doc(tmpRoomId).collection("messages").add({
			text: fallbackMessage,
			uid: provider,
			// createdAt: new Date(), //firestore.FieldValue.serverTimestamp(),
			createdAt: firebase.firestore.FieldValue.serverTimestamp(),
			options: options,
			greeting: true,
			chatbotName: chatbotName,
			isTimeKeeper: false
		});
	}
}

// Create a Broadcast Channel with a unique name
const broadcastChannel = new BroadcastChannel('clientIdChannel');

function App() {
	const [chatOpen, setChatOpen] = useState(false);
	const [brandingTitle, setBrandingTitle] = useState("");
	const [subtitle, setSubtitle] = useState("");
	const [footer, setFooter] = useState("");
	const [avatarUrl, setAvatarUrl] = useState("");
	const [{ view, user, colorSettings }, dispatch] = useStateValue();
	const [authState, authLoading, authError] = useAuthState(auth);
	const [online, setOnline] = useState(true);
	const [brandingLoaded, setBrandingLoaded] = useState(true);
	const [appMounted, setAppMounted] = useState(false);
	const [loading, setLoading] = useState(true);
	const [loginError, setLoginError] = useState(false);
	const [justOpen, setJustOpen] = useState(false);
	const [chatbotIsDraft, setChatbotIsDraft] = useState(false);
	const [bypassDraft, setBypassDraft] = useState(false);
	const [loginLoading, setLoginLoading] = useState(false);
	// State to hold mapped messages
	const [messagesState, setMessagesState] = useState([]);

	const messagesEndRef = useRef(null);
	let clientId = getQueryVariable("id") ? getQueryVariable("id") : "1001"
	let chatbotRef = null;
	const initialTimerRef = useRef(null);
	// Instead of a single ref for a single timer, use an object keyed by messageId
	const timersRef = useRef({});
	const processedMessageIdsRef = useRef(new Set());
	const itWaitedLongTimeRef = useRef(false);
	const { instance, accounts, inProgress } = useMsal(); //MS

	const [loadingMS, setLoadingMS] = useState(false);
	const [apiData, setApiData] = useState(null);

	useEffect(() => {
		console.log("MS useEffect");
		// Immediately define and invoke the async function inside useEffect
		(async () => {
			console.log("MS useEffect asybc function", inProgress);
			console.log("MS useEffect asybc function loadingMS", loadingMS);
			console.log("MS useEffect asybc function accounts.length", accounts.length);
			if (!loadingMS && inProgress === InteractionStatus.None && accounts.length > 0) {
				console.log("MS useEffect inside IF")
				if (apiData) {
					// Skip data refresh if already set - adjust logic for your specific use case
					console.log("MS useEffect apiData", apiData)
					return;
				}

				//Get the clientId to use fo MicrosoftLogin
				//from firestore collection chatbots
				//document chatbotId
				//key clientIdSsoMs
				const chatbotDataTmp = await firestore
					.collection("chatbots")
					.doc(clientId)
					.get()

				const chatbotDocVal = chatbotDataTmp.data()
				console.log("GET CHATBOT INFO", chatbotDocVal);
				if (!chatbotDocVal) {
					return
				}
				console.log("clientIdSsoMs", chatbotDocVal.clientIdSsoMs)
				const tmpClientIdSsoMs = chatbotDocVal.clientIdSsoMs;

				const tokenRequest = {
					account: accounts[0], // This is an example - Select account based on your app's requirements
					//Sofina
					scopes: ["api://94ae8535-733a-4d84-82b0-d2056a9a47c5/sso"]
					//Premise
					// scopes: ["api://1dadd136-e76c-4a32-bf5b-ed82ba32f883/sso"]
				}

				// Acquire an access token
				try {
					// instance.acquireTokenSilent(tokenRequest).then(async (loginResult) => {
					const loginResult = await instance.acquireTokenSilent(tokenRequest);
					// Call your API with the access token and return the data you need to save in state
					console.log("MS useEffect response", loginResult)
					setLoginLoading(true);
					setLoading(true)
					// const url = "https://service.premisehq.co:8005/api/sail-user-create"; // with roles
					const url = "https://api.premisehq.co/v3/workflows/sail-user-create";
					const email = loginResult.account.username;
					const uid = loginResult.uniqueId;
					// Assign the first part to firstName and the second to lastName
					const firstName = loginResult.account.name; //nameParts[0];
					const lastName = ""; //nameParts[1];

					// Track the user visit after successful authentication
					trackUserVisit(clientId, uid, {
						userName: firstName,
						userEmail: email
					}).catch(error => {
						console.error('Error tracking user visit:', error);
					});

					const dataReq = {
						oid: loginResult.account.idTokenClaims.oid, //required,
						firstName: firstName, //empty string if value is not present
						lastName: lastName, //empty string if value is not present
						phoneWork: "", //empty string if value is not present
						email: email, //empty string if value is not present
						language: "EN", //required
						gender: "male", //required
						birthDate: "", //empty string if value is not present
					};
					const config = {
						headers: {
							'Authorization': `Bearer ${loginResult.accessToken}`, // Replace 'your_access_token_here' with the actual token
							'Content-Type': 'application/json'
						}
					};

					const response = await axios.post(url, dataReq, config);
					console.log('User Created Successfully:', response);
					console.log('User roles:', response.data.roles);
					const rules = response.data.roles;
					const access = rules.includes("General")
					console.log('access', access);
					const prevUser = firebase.auth().currentUser;
					const prevId = prevUser.uid;
					console.log('prevId', prevId);
					setLoginLoading(true);
					setLoading(true);

					// Check if the user with the provided email exists but is not authenticated
					const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(email);
					let userNew;
					if (signInMethods.length === 0) {
						// User does not exist, create the new user by the dedicated API if this user need use Sail
						console.log('User does not exist');

						// User does not exist, create the new user
						const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, uid);
						// Registration successful. You can access the new user's information from userCredential.user
						await firebase
							.auth()
							.currentUser.updateProfile({
								displayName: email,
							});
						userNew = userCredential.user;
						console.log('Registered user:', userNew);
						// Set the displayName property of the user
						try {
							console.log("Display name set successfully!");
						} catch (error) {
							console.error("Error setting display name:", error.message);
						}
						try {
							// Refresh the user's data after adding the claim
							await firebase.auth().currentUser.reload();
						} catch (error) {
							console.error("Error setting display name:", error.message);
						}
					} else {
						// User exists, sign in the user
						console.log('uid', uid);
						const userCredential = await firebase.auth().signInWithEmailAndPassword(email, uid);
						// Login successful. You can access the user's information from userCredential.user
						await firebase
							.auth()
							.currentUser.updateProfile({
								displayName: email,
							});
						userNew = userCredential.user;
						console.log('Logged in user:', userNew);
					}

					//From here use user from firebase to set the chatbot value
					// const chatbotId = clientId;
					console.log("chatbotId, ", clientId)
					//get userID
					console.log("userNew", userNew)
					console.log("userId", userNew.uid)
					const userId = userNew.uid;
					const userEmail = userNew.email;
					console.log("userEmail", userEmail)
					const snapShot = await firestore
						.collection("rooms")
						.doc(userId + "***" + clientId)
						.get();

					if (!snapShot.exists) {
						console.log(" room does not exist");
						console.log("userId", userId);
						await firestore
							.collection("rooms")
							.doc(userId + "***" + clientId)
							.set({
								pendingMessages: 0,
								chatbotId: clientId,

								// userEmail: userEmail,
								// email: userEmail,
								// userName: firstName,
								// userFirstName: firstName,

								userEmail: userEmail,
								userEmail_lowercase: userEmail.toLowerCase(),
								email: userEmail,
								email_lowercase: userEmail.toLowerCase(),
								userName: firstName,
								userName_lowercase: firstName.toLowerCase(),
								userFirstName: firstName,
								userFirstName_lowercase: firstName.toLowerCase(),

								userId: userId,
								access: access ? access : false,
							}, { merge: true });

					} else {
						//to make sure that a login user will always have a name and email
						//room already exist but re-write userEmail and userName
						console.log(" room ALREADY EXIST ");
						await firestore
							.collection("rooms")
							.doc(userId + "***" + clientId)
							.set({
								// userEmail: userEmail,
								// email: userEmail,
								// userName: firstName,
								// userFirstName: firstName,

								userEmail: userEmail,
								userEmail_lowercase: userEmail.toLowerCase(),
								email: userEmail,
								email_lowercase: userEmail.toLowerCase(),
								userName: firstName,
								userName_lowercase: firstName.toLowerCase(),
								userFirstName: firstName,
								userFirstName_lowercase: firstName.toLowerCase(),

								chatbotId: clientId,
								userId: userId,
								access: access ? access : false,
							}, { merge: true });
					}

					// After successful login
					// Update user status to true (online)
					// const userId = firebase.auth().currentUser.uid;
					// const clientId = chatbotId
					const userRef = db.ref(`rooms/${userId}/clientIds/${clientId}`);
					userRef.set(true).catch(error => {
						console.error('Error updating user status:', error);
					});

					//***WARNING when running the emulator httpsCallable will not work properly and will generete an error */
					await functions.httpsCallable(
						"transferDelete"
					)({
						prevId: prevId,
						userId: userId,
						chatbotId: clientId,
						firstName: firstName
					});

					//After the login is completed, before to change view
					//delete the previous anonymous user
					console.log("prevId", prevId)
					console.log("prevUser", prevUser)
					if (prevId !== userId) {
						prevUser.delete().then(() => {
							// User deleted.
							console.log("User deleted" + prevId)
							setLoginLoading(false);
							// setLoading(true)

							console.log("user", userNew)
							console.log("firstName", firstName)
							console.log("userId", userId)

							dispatch({
								type: "USER",
								user: {
									...userNew,
									name: firstName,
									id: userId,
									loggedIn: true,
								},
							});
							dispatch({
								type: "SET_VIEW",
								view: VIEW.CHATROOM,
							});
						}).catch((error) => {
							// An error ocurred
							console.log("User deleted ERRR: ", error)
						});
					} else {
						setLoginLoading(false);
						console.log("user", userNew)
						console.log("firstName", firstName)
						console.log("userId", userId)
						dispatch({
							type: "USER",
							user: {
								...userNew,
								name: firstName,
								id: userId,
								loggedIn: true,
							},
						});
						dispatch({
							type: "SET_VIEW",
							view: VIEW.CHATROOM,
						});
					}
				} catch (error) {
					console.error('Error creating user:', error.response ? error.response.data : error.message);
					// Handle error here
				}
			}
		})();
	}, [inProgress, accounts, instance, loadingMS, apiData]);

	//This use effect is required only in the app-open
	//because app-open is embedded in sail help and it login using token
	useEffect(() => {
		// Define an inner asynchronous function
		const fetchData = async () => {
			// on chatbot load get the token and login automatically
			const prevId = null;
			const token = extractTokenFromUrl();
			if (token) {
				try {
					const result = await firebase.auth().signInWithCustomToken(token);
					console.log("result of login by email", result.user.uid);
					console.log("result of login by email clientId", clientId);

					// Query Firestore for messages
					const roomId = result.user.uid + "***" + clientId; // Replace with your room ID
					const messagesRef = firebase.firestore().collection('rooms').doc(roomId).collection('messages');
					const messagesSnapshot = await messagesRef.get();

					// Check if messages collection is empty
					if (messagesSnapshot.empty) {
						// If messages collection is empty, add a new document to it
						//GET CHATBOT INFO
						firestore
							.collection("chatbots")
							.doc(clientId)
							.get()
							.then(async (res) => {
								const chatbotDocVal = res.data()
								console.log("GET CHATBOT INFO", chatbotDocVal);
								if (!chatbotDocVal) {
									return
								}
								const chatbotName = chatbotDocVal.name;
								const tmpGreetings = chatbotDocVal.greetings;
								const tmpOtions = chatbotDocVal.options;

								const fallbackMsg = "👋 Welcome! I quickly answer common questions or explore our resources for anything else. Ask away!"; //"Welcome!"
								messageToFirestore(tmpGreetings, fallbackMsg, tmpOtions, null, roomId, chatbotName)
							})
						console.log("Added new document to messages collection");
					} else {
						console.log("Messages collection is not empty, do nothing");
					}

				} catch (err) {
					console.log("error check", err);
				}
			}
		};

		fetchData();
	}, []);

	// allow desired origins
	// State to store the result of the validation check
	const [isValid, setIsValid] = useState(false);

	useEffect(() => {
		// Define an inner asynchronous function
		async function fetchData() {
			// Retrieve origin and parent URL (referrer)
			const isEmbedded = window.top !== window.self;
			const origin = window.location.origin;
			const parentUrl = document.referrer;

			// If not embedded, just allow
			if (!isEmbedded) {
				setIsValid(true);
				return;
			}

			// Get expected parent URL values
			const expectedParentUrls = await getUrlValues(clientId);
			console.log("expectedParentUrls", expectedParentUrls);
			console.log("origin", origin);
			console.log("parentUrl", parentUrl);

			// Perform validation checks
			if ((parentUrl && parentUrl !== "https://console.firebase.google.com/") && expectedParentUrls?.includes(parentUrl)) {
				console.log("Access Ok!~~~~~~~~~~~");
				setIsValid(true); // Set isValid to true if validation passes
			} else if ((parentUrl && parentUrl !== "https://console.firebase.google.com/") && !expectedParentUrls?.includes(parentUrl)) {
				console.log("Unauthorized access!~~~~~~~~~~~");
				setIsValid(false);
			} else {
				console.log("Not embedded~~~~~~~~~~~");
				setIsValid(true); // Set isValid to true if validation passes
			}
		}
		// Call the inner asynchronous function
		fetchData();
	}, [clientId]);

	useEffect(() => {
		// Unsubscribe from the document snapshot listener when the component unmounts
		return () => {
			broadcastChannel.close();
		}
	}, []);

	//Manage user online status 
	useEffect(() => {
		console.log("authState", authState);
		if (!authState) return;

		console.log("authState UID ", authState?.uid);
		const userId = authState.uid;

		// Function to update user status
		const updateUserStatus = (status) => {
			const userRef = db.ref(`rooms/${userId}/clientIds/${clientId}`);
			userRef.set(status);
		};

		// Set initial user status to true ("online") when the component mounts
		updateUserStatus(true);

		// Set up a listener for beforeunload and unload events
		const handleUnload = () => {
			console.log("handleUnload");
			updateUserStatus(false);
		};

		window.addEventListener('beforeunload', handleUnload);
		window.addEventListener('unload', handleUnload);

		// Cleanup function to remove the listeners and set user status to false
		return () => {
			console.log("cleanup getUserStatus");
			updateUserStatus(false);
			window.removeEventListener('beforeunload', handleUnload);
			window.removeEventListener('unload', handleUnload);
		};
	}, [authState, authState?.uid, clientId]);

	//Get client ID from embed URL and set custom branding on first load
	//Set custom branding when color settings change
	useEffect(() => {
		applyClientBranding(colorSettings);

		// THIS LOGIC GETS PREVIOUS ACCESSIBILLITY SETTINGS FROM LOCAL STORAGE AND SETS ON NEW CHATBOT LOAD
		const previousFontSize = localStorage.getItem("fontSizeSetting");
		const previousVisibilitySetting =
			localStorage.getItem("visibilitySetting");

		if (previousFontSize === FONT_SIZE.LARGE) {
			dispatch({
				type: "SET_FONT_SIZE",
				fontSize: FONT_SIZE.LARGE,
			});
			resizeGlobalFonts(FONT_SIZE.LARGE);
		} else if (previousFontSize === FONT_SIZE.SMALL) {
			dispatch({
				type: "SET_FONT_SIZE",
				fontSize: FONT_SIZE.SMALL,
			});
			resizeGlobalFonts(FONT_SIZE.SMALL);
		}

		if (previousVisibilitySetting === VISIBILITY.MONOCHROME) {
			dispatch({
				type: "SET_VISIBILITY",
				visibility: VISIBILITY.MONOCHROME,
			});
			updateVisibilitySetting(VISIBILITY.MONOCHROME);
		} else if (previousVisibilitySetting === VISIBILITY.HIGH_CONTRAST) {
			dispatch({
				type: "SET_VISIBILITY",
				visibility: VISIBILITY.HIGH_CONTRAST,
			});
			updateVisibilitySetting(VISIBILITY.HIGH_CONTRAST);
		}
	}, [colorSettings, dispatch]);

	const getMessageFromParent = (e) => {
		// console.log("e.origin", e.origin)
		// console.log("parentURL", parentURL)
		if (e.origin.startsWith(parentURL)) {
			// console.log("message from parent url inside e.ORIGIN", parentURL)
			if (e.data === "chatbotStatus") {
				setBypassDraft(true)
			}
			// console.log("e.data.shouldSignOut", e.data.shouldSignOut)
			if (e.data.shouldSignOut) {
				auth.signOut()
					.then(() => {
						console.log('User signed out successfully');
						// Perform any additional actions after signing out
						window.parent.postMessage({ signOutCompleted: true }, parentURL) //"*"); //'https://parent-app-domain.com');
					})
					.catch((error) => {
						console.error('Error signing out user:', error);
						window.parent.postMessage({ signOutCompleted: false }, parentURL) //"*"); //'https://parent-app-domain.com');
					});
			}
		}
	}

	//Page reload handle
	useEffect(() => {
		window.addEventListener("message", getMessageFromParent)
		window.addEventListener("beforeunload", alertUser);
		if (sessionStorage.getItem("reloading") === "true") { }

		return () => {
			sessionStorage.setItem("reloading", "true");
		};
	}, [])

	const alertUser = (e) => {
		sessionStorage.setItem("reloading", "true");
		const prevUser1 = auth.currentUser;
		sessionStorage.setItem("prevUser", JSON.stringify(prevUser1));
	}

	useEffect(() => {
		//this condition is necessary to wait until the current user is retrive from Firebase on paging load
		//until that moment do not do anything just wait 
		if (!authLoading) {
			const prevUser2 = auth.currentUser;
			// IF CHANGED CHATBOT ID
			// change room too
			if (JSON.parse(sessionStorage.getItem("clientId")) !== clientId) {
				console.log("PAGE LOAD CHATBOT ID CHANGED")
				//GET CHATBOT INFO FROM FIRESTORE
				let defClientID = clientId ? clientId : "1001"
				// Parse JSON if defClientID is not a string
				if (typeof defClientID !== "string") {
					defClientID = String(JSON.parse(defClientID));
				}
				//GET CHATBOT INFO
				firestore
					.collection("chatbots")
					.doc(defClientID)
					.get()
					.then(async (res) => {
						const chatbotDocVal = res.data()
						console.log("GET CHATBOT INFO", chatbotDocVal);
						if (!chatbotDocVal) {
							return
						}
						let client = {}

						setBrandingTitle(chatbotDocVal.name);
						setSubtitle(chatbotDocVal.subtitle);
						setFooter(chatbotDocVal.footer);
						setAvatarUrl(chatbotDocVal.avatarUrl);
						client = {
							defaultAgent: chatbotDocVal.defaultAgent ? chatbotDocVal.defaultAgent : "null",
							name: chatbotDocVal.name,
							chatbotId: defClientID,
							defaultAgentId: chatbotDocVal.skills[0] ? chatbotDocVal.skills[0] : "null",
							greetings: chatbotDocVal.greetings,
							options: chatbotDocVal.options,
						}
						dispatch({
							type: "COLOR_SETTINGS",
							colorSettings: {
								contrast: chatbotDocVal.contrastColor,
								primary: chatbotDocVal.primaryColor,
								accent: chatbotDocVal.accentColor,
							},
						});

						sessionStorage.setItem("CLIENT", JSON.stringify(client));
						setAppMounted(true);
						const token = extractTokenFromUrl();
						//Check if prev user is anonymous or not
						if (prevUser2?.isAnonymous) {
							//If prev user was anonymous and the chatbotId chnaged delete the previous anonymous user
							console.log("PAGE LOAD CHATBOT ID CHANGED PREV user ANONYMOUS")
							auth.signOut().then(() => {
								// User deleted.
								console.log("prevUserBeforeReload DELETED")
								// Create new anonymous user
								auth.signInAnonymously()
									.then((currentUser) => {
										const tmpRoomId = currentUser.user.uid + "***" + defClientID
										const roomValue = {
											chatbotId: defClientID,
										}
										if (JSON.parse(sessionStorage.getItem("CLIENT"))?.defaultAgent) {
											roomValue.provider = JSON.parse(sessionStorage.getItem("CLIENT"))?.defaultAgent;
										}
										if (JSON.parse(sessionStorage.getItem("CLIENT"))?.defaultAgentId) {
											roomValue.providerId = JSON.parse(sessionStorage.getItem("CLIENT"))?.defaultAgentId;
										}

										firestore
											.collection("rooms")
											.doc(tmpRoomId)
											.set(roomValue, { merge: true })
											.then(() => {
												//If the trigger for form is first request
												if (chatbotDocVal.trigger === "After First Request") {
													writeForm(currentUser.user.uid, defClientID)
												} else {
													writeGreetingsOptions(currentUser.user.uid, defClientID)
												}

												//check userType in local storage
												localStorage.removeItem("userType");
												dispatch({
													type: "ADMIN_SETTINGS",
													admin: {
														adminUser: false,
														feedbackOn: false,
													},
												});

												dispatch({
													type: "USER",
													user: {
														...user,
														id: currentUser?.user.uid,
														loggedIn: false,
														name: null,
													},
												});
											})
											.catch((error) => {
												console.error("Error writing document: ", error);
											});
									})
									.catch((err) => {
										console.log("ERROR", err);
									});
							}).catch((error) => {
								console.log("error sign out anonymous ERRR")
							});
						} else if (prevUser2) {
							console.log("PAGE LOAD CLIENT ID CHANGED PREV user NOT ANONYMOUS")
							const tmpRoomId = prevUser2.uid + "***" + defClientID
							console.log("tmpRoomId", tmpRoomId);
							//CHATBOT GREETINGS AND OPTIONS
							writeGreetingsOptions(prevUser2.uid, defClientID)
								.then(async () => {
									firestore
										.collection("rooms")
										.doc(tmpRoomId)
										.get()
										.then((oldRoomData) => {
											firestore
												.collection("rooms")
												.doc(tmpRoomId)
												.set({
													chatbotId: clientId ? clientId : '1001',
													// userEmail: oldRoomData.data().userEmail,
													// email: oldRoomData.data().userEmail,
													// userName: oldRoomData.data().userName,
													// userFirstName: oldRoomData.data().userName,

													userEmail: oldRoomData.data().userEmail,
													userEmail_lowercase: oldRoomData.data().userEmail.toLowerCase(),
													email: oldRoomData.data().userEmail,
													email_lowercase: oldRoomData.data().userEmail.toLowerCase(),
													userName: oldRoomData.data().userName,
													userName_lowercase: oldRoomData.data().userName.toLowerCase(),
													userFirstName: oldRoomData.data().userName,
													userFirstName_lowercase: oldRoomData.data().userName.toLowerCase(),

												}, { merge: true })
												.then(() => {
													//check userType in local storage
													//Check if usertype User is present in the local storage.
													//If yes the existing user was logged in by email and have flag access
													const userType = localStorage.getItem("userType");
													if (userType === "User") {
														dispatch({
															type: "ADMIN_SETTINGS",
															admin: {
																adminUser: true,
																feedbackOn: true,
															},
														});
													}

													dispatch({
														type: "USER",
														user: {
															...user,
															id: prevUser2?.uid,
															loggedIn: true,
															name: prevUser2?.displayName,
														},
													});
												})
												.catch((error) => {
													console.error("Error writing document: ", error);
												});
										})
										.catch((error) => {
											console.error("Error writing document: ", error);
										});
								})
								.catch((err) => {
									console.log("ERROR snap", err);
								});
						} else if (!prevUser2 && !token) {
							console.log("PAGE LOAD CLIENT ID CHANGED PREV user NULLLLLL")
							//if NOT prev user mean this is the first time the page open so create anonymou user
							// console.log("create anonymous user the first time")
							//Create new anonymous user
							auth.signInAnonymously()
								.then((currentUser) => {
									const tmpRoomId = currentUser.user.uid + "***" + defClientID
									firestore
										.collection("rooms")
										.doc(tmpRoomId)
										.set({
											chatbotId: clientId ? clientId : '1001',
										}, { merge: true })
										.then(() => {//If the trigger for form is first request
											if (chatbotDocVal.trigger === "After First Request") {
												writeForm(currentUser.user.uid, defClientID)
											} else {
												writeGreetingsOptions(currentUser.user.uid, defClientID)
											}
											//check userType in local storage
											localStorage.removeItem("userType");
											dispatch({
												type: "ADMIN_SETTINGS",
												admin: {
													adminUser: false,
													feedbackOn: false,
												},
											});
											dispatch({
												type: "USER",
												user: {
													...user,
													id: currentUser?.user.uid,
													loggedIn: false,
													name: null,
												},
											});
										})
										.catch((error) => {
											console.error("Error writing document: ", error);
										});
								})
								.catch((err) => {
									console.log("ERROR", err);
								});
						}
					})
				sessionStorage.setItem("clientId", JSON.stringify(clientId))
			} else {
				console.log("PAGE LOAD CLIENT ID NOOOOOT CHANGED")
				//If the url did not change on first reload check in prevuser exist
				//If exist do notthing 
				// GET CHATBOT INFO FROM BIGQUERY
				let defClientID = clientId ? clientId : "1001";
				console.log(defClientID);
				console.log(typeof defClientID);

				// Parse JSON if defClientID is not a string
				if (typeof defClientID !== "string") {
					defClientID = String(JSON.parse(defClientID));
				}

				//GET CHATBOT INFO
				firestore
					.collection("chatbots")
					.doc(defClientID)
					.get()
					.then(async (res) => {
						const chatbotDocVal = res.data()
						console.log("GET CHATBOT INFO", chatbotDocVal);
						let client = {}
						setBrandingTitle(chatbotDocVal.name);
						setSubtitle(chatbotDocVal.subtitle);
						setFooter(chatbotDocVal.footer);
						setAvatarUrl(chatbotDocVal.avatarUrl);
						client = {
							defaultAgent: chatbotDocVal.defaultAgent ? chatbotDocVal.defaultAgent : "null",
							name: chatbotDocVal.name,
							chatbotId: defClientID,
							defaultAgentId: chatbotDocVal.skills[0] ? chatbotDocVal.skills[0] : "null",
						}
						dispatch({
							type: "COLOR_SETTINGS",
							colorSettings: {
								contrast: chatbotDocVal.contrastColor,
								primary: chatbotDocVal.primaryColor,
								accent: chatbotDocVal.accentColor,
							},
						});
						sessionStorage.setItem("CLIENT", JSON.stringify(client));
						setAppMounted(true)

						//If do not exist create an anonymous user
						if (!prevUser2) {
							console.log("PAGE LOAD CLIENT ID NOOOOOT CHANGED NOOO prev user")
							//Create new anonymous user
							auth.signInAnonymously()
								.then((currentUser) => {
									console.log("-------------STET CHATBOT-----------")
									const tmpRoomId = currentUser.user.uid + "***" + defClientID
									console.log("tmpRoomId", tmpRoomId);
									console.log("defClientID", defClientID);
									firestore
										.collection("rooms")
										.doc(tmpRoomId)
										.set({
											chatbotId: defClientID,
										}, { merge: true })
										.then(() => {
											if (chatbotDocVal.trigger === "After First Request") {
												writeForm(currentUser.user.uid, defClientID)
											} else {
												writeGreetingsOptions(currentUser.user.uid, defClientID)
											}
											//check userType in local storage
											localStorage.removeItem("userType");
											dispatch({
												type: "ADMIN_SETTINGS",
												admin: {
													adminUser: false,
													feedbackOn: false,
												},
											});
											dispatch({
												type: "USER",
												user: {
													...user,
													id: currentUser?.user.uid,
													loggedIn: false,
													name: null,
												},
											});
										})
										.catch((error) => {
											console.error("Error writing document: ", error);
										});
								})
								.catch((err) => {
									console.log("ERROR", err);
								});
						} else if (prevUser2?.isAnonymous) {
							console.log("PAGE LOAD CLIENT ID NOOOOOT CHANGED prev user ANONYMOUS")

							localStorage.removeItem("userType");
							dispatch({
								type: "ADMIN_SETTINGS",
								admin: {
									adminUser: false,
									feedbackOn: false,
								},
							});

							dispatch({
								type: "USER",
								user: {
									...user,
									id: prevUser2.uid,
									loggedIn: false,
									name: null,
								},
							});
						} else if (prevUser2) {
							console.log("PAGE LOAD CLIENT ID NOOOOOT CHANGED prev user NOOOOT ANONYMOUS")
							const snapRoom = await firestore
								.collection("rooms")
								.doc(prevUser2.uid + "***" + defClientID)
								.get();

							const roomDdata = snapRoom.data();
							const isAdmin = roomDdata.isAdmin;
							//Check if usertype User is present in the local storage.
							//If yes the existing user was logged in by email and have flag access
							if (isAdmin) {
								dispatch({
									type: "ADMIN_SETTINGS",
									admin: {
										adminUser: true,
										feedbackOn: true,
									},
								});
							}
							dispatch({
								type: "USER",
								user: {
									...user,
									id: prevUser2.uid,
									loggedIn: true,
									name: prevUser2.displayName,
								},
							});
						}
					}).catch((error) => {
						console.log("prevUserBeforeReload ERRR")
					});
			}
		}
	}, [authLoading, clientId])

	// Handle chatbot open/close communication to parent page
	useEffect(() => {
		if (chatOpen) {
			window.parent.postMessage('{"state":"open"}', "*");
		} else {
			window.parent.postMessage('{"state":"close"}', "*");
		}
	}, [chatOpen]);

	// Handle chatbot loaded communication to parent page
	useEffect(() => {
		if (appMounted) {
			window.parent.postMessage('{"appMounted":"true"}', "*");
			setChatOpen(true) //add Sept11 2023 for app-open
			setJustOpen(true) //add Sept11 2023 for app-open
		} else {
			window.parent.postMessage('{"appMounted":"false"}', "*");
		}
	}, [appMounted]);

	let messages = [];

	// Handle chatbots Collection / Updates
	const client = JSON.parse(sessionStorage.getItem("CLIENT"))

	if (authState && authState.uid && client?.chatbotId) {
		chatbotRef = firestore
			.collection("chatbots")
			.doc(client.chatbotId)
	}

	const [chatbotStatusVal, chatbotStatusLoading, chatbotStatusErr] = useDocument(chatbotRef);

	useEffect(() => {
		const chatbotStatus = chatbotStatusVal?.data()?.status
		if (chatbotStatusVal) {
			//if change status is draft display message and disable input field
			if (chatbotStatusLoading === false && chatbotStatus === "draft") {
				setChatbotIsDraft(true)
			} else if (chatbotStatusLoading === false && chatbotStatus === "online") {
				setChatbotIsDraft(false)
			}
			//chatbot Default agent
			const chatbotDefaultAgent = chatbotStatusVal?.data()?.defaultAgent;
			const chatbotDefaultAgentId = chatbotStatusVal?.data()?.skills ? chatbotStatusVal?.data()?.skills[0] : null;
			const chatbotSkills = chatbotStatusVal?.data()?.skills ? chatbotStatusVal?.data()?.skills : []
			// if change status is draft display message and disable input field
			const client = JSON.parse(sessionStorage.getItem("CLIENT"));
			const clientDefaultAgent = client?.defaultAgent
			//If defaultAgent changed update the default agent in the session storage CLIENT
			//the default agent can change only the actual default agent is removed from the chatbot
			if (chatbotStatusLoading === false && (clientDefaultAgent !== chatbotDefaultAgent)) {
				sessionStorage.setItem("CLIENT", JSON.stringify(
					{
						...client,
						defaultAgent: chatbotDefaultAgent ? chatbotDefaultAgent : "null",
						defaultAgentId: chatbotStatusVal?.data()?.skills ? chatbotStatusVal?.data()?.skills[0] : "null"
					}
				));

				//GET SKILLS IDs FOR CHATBOT FROM FIRESTORE
				let defClientID = client.chatbotId ? client.chatbotId : "1001"

				// Parse JSON if defClientID is not a string
				if (typeof defClientID !== "string") {
					defClientID = String(JSON.parse(defClientID));
				}

				firestore
					.collection("chatbots")
					.doc(defClientID)
					.get()
					.then((res) => {
						console.log("GET SKILLS ID OF CHATBOT data()", res.data());
						const chatbotDocVal = res.data()
						const skillsId = chatbotDocVal.skills;
						const chatbotName = chatbotDocVal.name;

						//Also chek if the provider in the room is NULL
						//set as provider the new default agent
						const tmpRoomId = auth.currentUser.uid + "***" + defClientID
						firestore
							.collection("rooms")
							.doc(tmpRoomId)
							.get()
							.then((roomDoc) => {
								if (roomDoc.exists) {
									const roomDocVal = roomDoc.data()
									if (roomDocVal.provider === null || roomDocVal.provider === 'null' ||
										!skillsId.includes(roomDocVal.providerId
										)) {
										// or check if the room Provider is not included in the current skills present in the chatbot 
										firestore
											.collection("rooms")
											.doc(tmpRoomId)
											.set({ ...roomDocVal, provider: chatbotDefaultAgent, providerId: chatbotDefaultAgentId })
											.then(() => {
												const newDate = new Date();
												if (chatbotSkills.length === 0) {
													//Also if changing provider display a message to inform the user
													firestore
														.collection("rooms")
														.doc(tmpRoomId)
														.collection("messages").add({
															text: `Sorry no skills are available in the chatbot.`,
															uid: null,
															createdAt: newDate,
															options: null,
															chatbotName: chatbotName,
															isTimeKeeper: false
														})
												}
											})
											.catch((error) => {
												console.log("Error oomDoc provider", error);
											});
									}
								} else {
									console.log("roomDoc No such document!");
								}
							})
							.catch((error) => {
								console.log("Error getting document:", error);
							});
					})
					.catch((error) => {
						console.log("Error roomDoc provider", error);
					});
			} else if (client && chatbotStatusLoading === false && (clientDefaultAgent === chatbotDefaultAgent) && chatbotStatus !== "draft") {
				//if logic reach this point is because the chatbotstatus changed but is still live and the default agent is still the same
				//therefore one of the skills has been added or removed
				//So it needs to check if the current provider of the room is still among the actual skills
				//if yes do nothing
				//if not change the actual provider of the room to the default agent and inform the user by message
				//Because the current provider is by name not id, I need to collect all the skills'name still present in the chatbot
				//GET SKILLS IDs FOR CHATBOT FROM FIRESTORE
				let defClientID = client.chatbotId ? client.chatbotId : "1001"

				// Parse JSON if defClientID is not a string
				if (typeof defClientID !== "string") {
					defClientID = String(JSON.parse(defClientID));
				}
			}
		}
	}, [chatbotStatusLoading, chatbotStatusVal])

	// Handle Message Collection / Updates
	let messagesRef;

	if (authState && authState.uid) {
		messagesRef = firestore
			.collection("rooms")
			.doc(authState.uid + "***" + clientId)
			.collection("messages");
	}

	const query = messagesRef
		? messagesRef.orderBy("createdAt").limitToLast(50)
		: "";

	const [messagesArray, msgLoading, msgError] = useCollection(query, {
		snapshotListenOptions: { includeMetadataChanges: true },
	});

	messages = messagesArray?.docs.map((doc) => {
		return { id: doc.id, data: doc.data({ serverTimestamps: "estimate" }) };
	});

	useEffect(() => {
		if (messages?.length && appMounted && !authLoading && authState && authState.uid) setLoading(false);
	}, [messages, appMounted, authLoading, authState])

	// Changes bot status color when firestore connection status changes
	useEffect(() => {
		if (messagesArray) {
			const updateStyle = document.body.style;
			if (messagesArray.metadata.fromCache) {
				setOnline(false);
				updateStyle.setProperty(
					"--bot-status",
					"var(--bot-status-disconnected"
				);
			} else {
				setOnline(true);
				updateStyle.setProperty(
					"--bot-status",
					"var(--bot-status-connected"
				);
			}
		}
	}, [messagesArray]);

	const toggleChatWindow = () => {
		chatOpen ? setChatOpen(false) : setChatOpen(true);
		!chatOpen && setJustOpen(true);
	};

	//this function run on message submit
	function handleJustOpen() {
		setJustOpen(false);
	}

	const fetchingMessages =
		authLoading || loading || msgLoading || loginLoading ? true : false;

	let periodicTimer;
	let initialTimer;
	let itWaitedLongTime = false;

	// Effect to map messagesArray to messages state
	useEffect(() => {
		const mappedMessages = messagesArray?.docs.map(doc => ({
			id: doc.id,
			data: doc.data({ serverTimestamps: "estimate" }),
		})) || [];
		setMessagesState(mappedMessages);
	}, [messagesArray]);

	const handleFallbackMessage = async (messageId) => {
		console.log("ADD new message to firestore feedback")
		console.log("handleFallbackMessage message ID:", messageId);

		try {
			//get timekeeper value
			const { timeKeeper, timeKeeperInitialDelay, timeKeeperRecurringDelay, chatbotName, fallbackWaitingTime } = await getTimekeeperValues(clientId);

			let waitingTime = fallbackWaitingTime || 20000; //20s

			if (timeKeeper) {
				waitingTime = (timeKeeperInitialDelay + timeKeeperRecurringDelay) + (timeKeeperRecurringDelay / 3);
			}

			//add message to the room
			const tmpRoomId = auth.currentUser.uid + "***" + clientId
			const roomRef = firestore.collection('rooms').doc(tmpRoomId);

			// //check if room liveAgent in progress do not add the message
			const roomData = await roomRef.get();
			let liveAgentStatus = roomData.data()?.liveAgent;

			if (liveAgentStatus === 'InProgress') {
				console.log("Live agent is in progress. Skipping fallback message.");
				return; // Prevent scheduling timer or adding message
			}

			// Start a new timer and store it by messageId
			const timerId = setTimeout(async () => {
				try {
					// console.log("inside initialTimer");
					itWaitedLongTimeRef.current = true;

					//add message to the room
					roomRef.collection('messages')
						.add({
							text: `I apologize, but getting the answer is taking longer then expected.`,
							uid: null,
							createdAt: firebase.firestore.FieldValue.serverTimestamp(),
							chatbotName: chatbotName,
							isFallbackFEmessage: true,
							isTimeKeeper: true, //key value required only to make query for last 50 messages for context  purpose to work correctly
							questionRef: messageId,
						});

					console.log("Fallback message added to Firestore.");
				} catch (error) {
					console.error("Error adding fallback message:", error);
				} finally {
					// Clean up the timer
					delete timersRef.current[messageId];
				}
			}, waitingTime);

			// Store the timer in the dictionary
			timersRef.current[messageId] = timerId;

		} catch (error) {
			console.error("Error handling fallback message:", error);
		}
	}

	// Effect to clear the timer on component unmount
	useEffect(() => {
		return () => {
			if (initialTimerRef.current) {
				clearTimeout(initialTimerRef.current);
			}
		};
	}, []);

	// Effect to monitor messages and clear the timer if the last message has 'questionMessageId'
	useEffect(() => {
		if (messagesState.length === 0 || !messagesRef) return;

		const lastMessage = messagesState[messagesState.length - 1];
		console.log("messages.length ", messagesState.length);
		console.log("lastMessage", lastMessage);

		// Check if this message should clear all timers
		if (lastMessage && lastMessage.data.isActingAsFinalResponse) {
			// Clear all timers
			for (const [msgId, timerId] of Object.entries(timersRef.current)) {
				clearTimeout(timerId);
			}
			// Reset the dictionary
			timersRef.current = {};

			// Handle any other cleanup or Firestore operations here
			return;
		}

		// Otherwise, if the message is just a normal "final" type that corresponds to a specific questionMessageId
		if (lastMessage && (lastMessage.data.questionMessageId
			// || lastMessage.data.isFinalMessage
		)) {
			console.log("Last message contains 'questionMessageId'. Clearing the timer.");
			const messageId = lastMessage.data.questionMessageId;

			if (processedMessageIdsRef.current.has(messageId)) {
				console.log(`Message ID ${messageId} has already been processed.`);
				return;
			}

			processedMessageIdsRef.current.add(messageId);

			console.log("Last message contains 'questionMessageId'. Clearing the timer.");
			// Clear only the specific timer related to this messageId, if it exists
			if (timersRef.current[messageId]) {
				clearTimeout(timersRef.current[messageId]);
				delete timersRef.current[messageId];
			}
			// If there's a questionMessageId, proceed with Firestore updates
			if (lastMessage.data.questionMessageId) {
				// Define an async function to handle Firestore operations
				const checkAndUpdateDocuments = async () => {
					try {
						// First Query: Check for documents with 'questionMessageId' == messageId
						const querySnapshot = await messagesRef.where('questionMessageId', '==', messageId).get();

						if (querySnapshot.empty) {
							console.log(`No documents found with questionMessageId = ${messageId}`);
							return;
						}

						console.log(`Found ${querySnapshot.size} document(s) with questionMessageId = ${messageId}`);

						// Second Query: Find documents with 'questionRef' == messageId
						const refQuerySnapshot = await messagesRef.where('questionRef', '==', messageId).get();

						if (refQuerySnapshot.empty) {
							console.log(`No documents found with questionRef = ${messageId}`);
							return;
						}

						console.log(`Found ${refQuerySnapshot.size} document(s) with questionRef = ${messageId}`);

						// Update each document found in the second query
						const batch = firestore.batch(); // Use batch to perform multiple updates atomically

						refQuerySnapshot.forEach(doc => {
							const docRef = messagesRef.doc(doc.id);
							batch.update(docRef, { cancelRequestBtnDisabled: true });
						});

						// Commit the batch
						await batch.commit();
						console.log(`Updated ${refQuerySnapshot.size} document(s) with cancelRequestBtnDisabled = true`);
					} catch (error) {
						console.error("Error in checkAndUpdateDocuments:", error);
					}
				};

				// Invoke the async function
				checkAndUpdateDocuments();
			}
		}
	}, [messagesState, messagesRef]);


	return isValid ? (
		<ThemeProvider theme={theme}>
			<div className="App">
				{brandingLoaded && (
					<>
						{chatOpen && (
							<ChatbotBody
								firestore={firestore}
								messagesRef={messagesRef}
								brandingTitle={brandingTitle}
								subtitle={subtitle}
								footer={footer}
								avatarUrl={avatarUrl}
								// setBrandingTitle={setBrandingTitle}
								chatOpen={chatOpen}
								messages={messages}
								userLoading={loading || loginLoading}
								fsLoading={fetchingMessages}
								error={authError || (msgError && !msgError.message.includes("Missing or insufficient permissions")) || loginError}
								online={online}
								justOpen={justOpen}
								handleJustOpen={handleJustOpen}
								ref={messagesEndRef}
								chatbotIsDraft={chatbotIsDraft && !bypassDraft}
								user={authState}
								userLoadingAU={authLoading}
								chatbotId={clientId}
								handleFallbackMessage={handleFallbackMessage}
							/>
						)}
						{/* {appMounted && (
							<ChatWindowButton
								chatOpen={chatOpen}
								toggleChatWindow={toggleChatWindow}
							/>
						)} */}
					</>
				)}
			</div>
		</ThemeProvider>
	) : null;
}

export default App;
